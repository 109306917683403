<script>
  import Layout from "../../../layouts/main";
  import appConfig from "@/app.config";
  import PageHeader from "@/components/page-header";
  import axios from 'axios';
  import Disclaimer from '@/components/widgets/disclaimer'

  /**
   * Saas Dashboard Component
   */
  export default {
    components: {
      Layout,
      PageHeader,
      Disclaimer
    },
    data() {
      return {
        dataLoaded: false,
        apiData: {
          symbol: '',
          name: ''
        },
        title: "Symbol",
        breadcrumbs: [
          {
            text: "ETFs",
            // href: "/",
            active: true, // remove to activate href
          },
        ],
      };
    },
    created() {
      this.getapiData()
      document.title = `${this.symbol} | ${appConfig.title}`
    },
    methods: {
      getapiData() {
        axios.get(
          'https://api.bulldata.ai/etfs/symbols/' + this.symbol,
          { headers: { token: process.env.VUE_APP_BULLDATA_API_TOKEN } }
        )
        .then(
          result => {
            this.apiData = result.data.data
            this.dataLoaded = true
            console.log("Data loaded.")
          },
          error => {
            console.error(error)
          }
        )
      },
      formatPrice(price) {
        if (price >= 1) {
          return new Intl.NumberFormat(
            'en-US', 
            { 
              style: 'currency', 
              currency: 'USD', 
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }
          ).format(price)
        }
        else {
          return new Intl.NumberFormat(
            'en-US', 
            { 
              style: 'currency', 
              currency: 'USD', 
              minimumFractionDigits: 4,
              maximumFractionDigits: 4
            }
          ).format(price)
        }
      },
      formatPercent (percent) {
        return new Intl.NumberFormat(
          'en-US', 
          { 
            style: 'percent', 
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
            signDisplay: 'always'
          }
        ).format(percent)
      },
      getScoreColor(score) {
        if(score < 0) {
          return '#DD3444'
        } else {
          return '#28A745'
        }
      },
      getScoreEmoji(score) {
        if (score >= .4) return '🚀'
        else if (score <= -.4) return '☢️'
        else if (score >= .2 && score < .4) return '🔥'
        else if (score <= -.2 &&	score > -.4) return '⚡'
        else if (score > -.2 && score < .2) return '🌱'
        else return '🌱'
      },
      getBadgeClass(score) {
        return {
          'badge badge-score-label badge-strong-buy': score >= 40,
          'badge badge-score-label badge-strong-sell': score <= -40,
          'badge badge-score-label badge-buy': (score >= 20) && (score < 40),
          'badge badge-score-label badge-sell': (score <= -20) && (score > -40),
          'badge badge-score-label badge-neutral': (score > -20) &&	(score < 20)
        }
      },
      getBadgeClassStrong(score) {
        return {
          'badge badge-score-strong badge-strong-buy': score >= 40,
          'badge badge-score-strong badge-strong-sell': score <= -40,
          'badge badge-score-strong badge-buy': (score >= 20) && (score < 40),
          'badge badge-score-strong badge-sell': (score <= -20) && (score > -40),
          'badge badge-score-strong badge-neutral': (score > -20) &&	(score < 20)
        }
      },
      getBadgeLabel(score) {
        if (score >= 40) return 'strong buy'
        else if (score <= -40) return 'strong sell'
        else if (score >= 20 && score < 40) return 'buy'
        else if (score <= -20 &&	score > -40) return 'sell'
        else if (score > -20 && score < 20) return 'neutral'
        else return ''
      },
      getColorClass(number) {
        if (number < 0) return 'color-down'
        else if (number >= 0) return 'color-up'
        else return ''
      },
      toSparklineSeries(array, symbol) {
        return [{
          name: symbol,
          data: array
        }]
      },
      getSparklineColor(item, days) {
        if (days == 7) {
          return (item.rel_deltas.rel_delta_7_days < 0) ? ['#ff0000'] : ['#008000']
        }
        else if (days == 30) {
          return (item.rel_deltas.rel_delta_30_days < 0) ? ['#ff0000'] : ['#008000']
        }
        else if (days == 360) {
          return (item.rel_deltas.rel_delta_200_days < 0) ? ['#ff0000'] : ['#008000']
        }
      },
      getSparklineChartOptions(item, days) {
        return {
          chart: {
            type: 'area',
            sparkline: {
              enabled: true
            }
          },
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          colors: this.getSparklineColor(item, days),
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              inverseColors: false,
              opacityFrom: 0.45,
              opacityTo: 0.05,
              stops: [25, 100, 100, 100]
            },
          },
          tooltip: {
            enabled: false,
          }
        }
      },
    },
    computed: {
      symbol () {
        return this.$route.params.symbol
      },
      page() {
        return {
          title: this.symbol,
          meta: [
            {
              name: "description",
              content: appConfig.description,
            },
          ],
        }
      },
      scoresData() {
        return {
          short_term: {
            term_label: 'Short-Term Scores',
            total_score: Math.round(this.apiData.key_metrics['1d']['short_term']['total_score'] * 100) || 0,
            emoji: this.getScoreEmoji(this.apiData.key_metrics['1d']['short_term']['total_score']) || '🌱',
            sub_scores: [
              {
                id: 'momentum',
                icon: 'bx bx-cycling',
                label: 'Momentum',
                score: Math.round(this.apiData.key_metrics['1d']['short_term']['momentum_total_score'] * 100) || 0,
                color: this.getScoreColor(this.apiData.key_metrics['1d']['short_term']['momentum_total_score'])
              },
              {
                id: 'trend',
                icon: 'bx bx-line-chart',
                label: 'Trend',
                score: Math.round(this.apiData.key_metrics['1d']['short_term']['trend_total_score'] * 100) || 0,
                color: this.getScoreColor(this.apiData.key_metrics['1d']['short_term']['trend_total_score'])
              },
              {
                id: 'volume',
                icon: 'bx bx-area',
                label: 'Volume',
                score: Math.round(this.apiData.key_metrics['1d']['short_term']['volume_total_score'] * 100) || 0,
                color: this.getScoreColor(this.apiData.key_metrics['1d']['short_term']['volume_total_score'])
              },
            ]
          },
          medium_term: {
            term_label: 'Medium-Term Scores',
            total_score: Math.round(this.apiData.key_metrics['1d']['medium_term']['total_score'] * 100) || 0,
            emoji: this.getScoreEmoji(this.apiData.key_metrics['1d']['medium_term']['total_score']) || '🌱',
            sub_scores: [
              {
                id: 'momentum',
                icon: 'bx bx-cycling',
                label: 'Momentum',
                score: Math.round(this.apiData.key_metrics['1d']['medium_term']['momentum_total_score'] * 100) || 0,
                color: this.getScoreColor(this.apiData.key_metrics['1d']['medium_term']['momentum_total_score'])
              },
              {
                id: 'trend',
                icon: 'bx bx-line-chart',
                label: 'Trend',
                score: Math.round(this.apiData.key_metrics['1d']['medium_term']['trend_total_score'] * 100) || 0,
                color: this.getScoreColor(this.apiData.key_metrics['1d']['medium_term']['trend_total_score'])
              },
              {
                id: 'volume',
                icon: 'bx bx-area',
                label: 'Volume',
                score: Math.round(this.apiData.key_metrics['1d']['medium_term']['volume_total_score'] * 100) || 0,
                color: this.getScoreColor(this.apiData.key_metrics['1d']['medium_term']['volume_total_score'])
              },
            ]
          },
          long_term: {
            term_label: 'Long-Term Scores',
            total_score: Math.round(this.apiData.key_metrics['1d']['long_term']['total_score'] * 100) || 0,
            emoji: this.getScoreEmoji(this.apiData.key_metrics['1d']['long_term']['total_score']) || '🌱',
            sub_scores: [
              {
                id: 'momentum',
                icon: 'bx bx-cycling',
                label: 'Momentum',
                score: Math.round(this.apiData.key_metrics['1d']['long_term']['momentum_total_score'] * 100) || 0,
                color: this.getScoreColor(this.apiData.key_metrics['1d']['long_term']['momentum_total_score'])
              },
              {
                id: 'trend',
                icon: 'bx bx-line-chart',
                label: 'Trend',
                score: Math.round(this.apiData.key_metrics['1d']['long_term']['trend_total_score'] * 100) || 0,
                color: this.getScoreColor(this.apiData.key_metrics['1d']['long_term']['trend_total_score'])
              },
              {
                id: 'volume',
                icon: 'bx bx-area',
                label: 'Volume',
                score: Math.round(this.apiData.key_metrics['1d']['long_term']['volume_total_score'] * 100) || 0,
                color: this.getScoreColor(this.apiData.key_metrics['1d']['long_term']['volume_total_score'])
              },
            ]
          },
        }
      },
    }
  }
</script>

<style scoped>

.small-card {
  background-color: #fff;
  box-shadow: none;
  border-width: 1px;
  border-color: #495057;
}
.score-card {
  background-color: #fff;
  border-width: 1px;
  border-color: #495057;
  box-shadow: none;
}
.asset-price {
  font-size: 27px;
  font-weight: 300;
  color: #495057;
}
.bulldata-score {
  font-size: 24px;
  font-weight: 400;
}
.fira {
  font-family: "Fira Code", "Lucida Console", monospace;
}
.color-down {
  color: #ff0000;
}
.color-up {
  color: #008000;
}
.rel-delta {
  font-size: 18px;
  font-weight: 300;
}
.badge-score-label {
  font-family: "Fira Code", "Lucida Console", monospace;
  font-size: 13px;
  font-weight: 300;
  padding: 0.5em 0.5em;
}
.badge-score-strong {
  font-family: "Fira Code", "Lucida Console", monospace;
  font-size: 13px;
  font-weight: 400;
  padding: 0.5em 0.5em;
}
.badge-buy {
  background-color: #008000;
}
.badge-sell {
  background-color: #ff0000;
}
.badge-strong-buy {
  background-color: #008000;
}
.badge-strong-sell {
  background-color: #ff0000;
}
.badge-neutral {
  background-color: #5f6875;
}

.table-first-row {
  border-top: 1px solid;
}

.score-header {
  font-weight: 600;
  text-transform: uppercase;
}

.score-row {
  font-family: "Lucida Console", "Courier New", monospace;
}

/* .table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > td,
.table-borderless > tfoot > tr > th,
.table-borderless > thead > tr > td,
.table-borderless > thead > tr > th {
  border: none;
} */

</style>

<template>
  <Layout>
    <p  v-if="!apiData.name" aria-hidden="true">
      <span class="placeholder col-2 placeholder-glow placeholder-lg mb-2"></span>
    </p>
    <PageHeader 
      :title="apiData.name" 
      :items="breadcrumbs" 
      :badge="apiData.symbol"
      v-else 
    />

    <div class="row" v-if="!dataLoaded">
      <div class="col-xl-4 col-md-6" v-for="item in 3" :key="item">
        <div class="card">
          <div class="card-body">
            <div>
              <h4 class="card-title mb-4 placeholder-glow">
                <span class="placeholder col-6"></span>
              </h4>
            </div>
            <b-card-text>
              <div class="font-size-13 score-header placeholder-glow">
                <span class="placeholder col-6"></span>
              </div>
              <div>
                <p class="placeholder-glow">
                  <span class="placeholder col-7"></span>
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-6"></span>
                  <span class="placeholder col-8"></span>
                </p>
              </div>
              <div class="font-size-13 score-header placeholder-glow">
                <span class="placeholder col-6"></span>
              </div>
              <div>
                <p class="placeholder-glow">
                  <span class="placeholder col-7"></span>
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-6"></span>
                  <span class="placeholder col-8"></span>
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-4"></span>
                </p>
              </div>
            </b-card-text>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="dataLoaded">
      <div class="col-xl-4 col-md-6" v-for="item of scoresData" :key="item.term_label">
        <div class="card score-card">
          <div class="card-body">
            <div>
              <h4 class="card-title mb-4">{{ item.term_label }}</h4>
            </div>
            <b-card-text>
              <div class="font-size-13 mb-0 text-primary score-header">
                Overall Bulldata Score
              </div>
              <div>
                <table
                  class="table table-centered align-middle text-nowrap table-borderless"
                >
                  <tbody>
                    <tr>
                      <td style="width: 1%" class="ps-0">
                        <div class="text-start bulldata-score fira">
                          <span :class="item.total_score < 0 ? 'text-danger' : 'text-success'">
                            {{ item.total_score }}
                          </span>
                        </div>
                      </td>
                      <td style="width: 1%">
                        <div class="text-start">
                          <span :class="getBadgeClassStrong(item.total_score)">
                            {{ getBadgeLabel(item.total_score) }}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div class="bulldata-score">
                          {{ item.emoji }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              
              <div class="font-size-13 mb-0 text-primary score-header">
                Score Breakdown
              </div>
              <div>
                <table
                  class="table table-centered align-middle text-nowrap table-borderless"
                >
                  <tbody>
                    <tr v-for="scoreItem in item.sub_scores" :key="scoreItem.id">
                      <td style="width: 30px" class="ps-0">
                        <div class="font-size-16 text-start">
                          <i :class="scoreItem.icon"></i>
                        </div>
                      </td>
                      <td style="width: 92px" class="ps-0">
                        <div class="text-start">
                          {{ scoreItem.label }}
                        </div>
                      </td>
                      <td style="width: 50px" class="ps-0">
                        <div class="text-end fira font-size-14">
                          <span :class="scoreItem.score < 0 ? 'text-danger' : 'text-success'">
                            {{ scoreItem.score }}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div class="text-start">
                          <span :class="getBadgeClass(scoreItem.score)">
                            {{ getBadgeLabel(scoreItem.score) }}
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-card-text>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="!dataLoaded">
      <div class="col-xl-3 col-sm-6" v-for="item in 4" :key="item">
        <div class="card">
          <div class="card-body text-center">
            <h4 class="card-title mb-2 placeholder-glow">
              <span class="placeholder col-6"></span>
            </h4>
            <div class="asset-price fira placeholder-glow">
              <span class="placeholder col-8"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="dataLoaded">
      <div class="col-xl-3 col-sm-6">
        <div class="card small-card">
          <div class="card-body text-center px-0">
            <h4 class="card-title mb-2">
              Latest Price
            </h4>
            <div class="asset-price fira">
              {{ formatPrice(apiData.latest_price) }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-6">
        <div class="card small-card">
          <div class="card-body text-center px-0">
            <h4 class="card-title mb-2">
              Last 7 Days
            </h4>
            <div class="row justify-content-center">
              <div class="col-auto">
                <apexchart
                  class="apex-charts"
                  height=40
                  width=100
                  type="area"
                  dir="ltr"
                  :series="toSparklineSeries(apiData.sparklines.close_last_7_days, apiData.symbol)"
                  :options="getSparklineChartOptions(apiData, 7)"
                ></apexchart>
              </div>
              <div class="col-auto my-2 rel-delta fira">
                <span :class="getColorClass(apiData.rel_deltas.rel_delta_7_days)">
                  {{ formatPercent(apiData.rel_deltas.rel_delta_7_days) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-6">
        <div class="card small-card">
          <div class="card-body text-center px-0">
            <h4 class="card-title mb-2">
              Last 30 Days
            </h4>
            <div class="row justify-content-center">
              <div class="col-auto">
                <apexchart
                  class="apex-charts"
                  height=40
                  width=100
                  type="area"
                  dir="ltr"
                  :series="toSparklineSeries(apiData.sparklines.close_last_30_days, apiData.symbol)"
                  :options="getSparklineChartOptions(apiData, 30)"
                ></apexchart>
              </div>
              <div class="col-auto my-2 rel-delta fira">
                <span :class="getColorClass(apiData.rel_deltas.rel_delta_30_days)">
                  {{ formatPercent(apiData.rel_deltas.rel_delta_30_days) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-6">
        <div class="card small-card">
          <div class="card-body text-center px-0">
            <h4 class="card-title mb-2">
              Last 200 Days
            </h4>
            <div class="row justify-content-center">
              <div class="col-auto">
                <apexchart
                  class="apex-charts"
                  height=40
                  width=100
                  type="area"
                  dir="ltr"
                  :series="toSparklineSeries(apiData.sparklines.close_last_200_days, apiData.symbol)"
                  :options="getSparklineChartOptions(apiData, 360)"
                ></apexchart>
              </div>
              <div class="col-auto my-2 rel-delta fira">
                <span :class="getColorClass(apiData.rel_deltas.rel_delta_200_days)">
                  {{ formatPercent(apiData.rel_deltas.rel_delta_200_days) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Disclaimer/>

  </Layout>
</template>
